import React from "react"
import Helmet from "../components/helmet/helmet"
import Menu from "../components/menu/menu"
import Top from "../sections/top/top"
import Review from "../sections/reviews/reviews"
import Authour from "../sections/authour/authour"
import Sample from "../sections/sample/sample"
import Store from "../sections/store/store"
// import Event from "../sections/events/events"
// import Subscription from "../sections/subscription/subscription"
import Footer from "../sections/footer/footer"

export default () => (
  <div>
    <Helmet />
    <Menu />
    <Top />
    <Review />
    <Authour />
    <Sample />
    <Store />
    {/* <Event /> */}
    {/* <Subscription /> */}
    <Footer />
  </div>
)
