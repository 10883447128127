import React from "react"
import SectionTitle from "../../components/sectionTitle/sectionTitle"
import Stars from "../../components/stars/stars"
import AmazonButton from "../../components/amazonButton/amazonButton"
import Divider from "../../components/divider/divider"
import review_items from "./review_items.json"
import styles from "./reviews.module.css"

const Nl2br = ({ str }) =>
  str.split(/[\n\r]/g).map((line, i) =>
    i === 0 ? (
      <span key={`line-${i}`}>{line}</span>
    ) : (
      <span key={`line-${i}`}>
        <br />
        {line}
      </span>
    )
  )

export default () => (
  <div id="review" className="container">
    <SectionTitle h={40}>Review</SectionTitle>
    {review_items.map((review, i) => (
      <div className={styles.wrapper} key={`review-${i}`}>
        <div className={styles.left}>
          {/* <img className={styles.profile} src="" alt="" /> */}
        </div>
        <div className={styles.right}>
          <p className={styles.title}>{review.title}</p>
          <Stars score={review.score} />
          <p className={styles.details}>
            By <span className={styles.name}>{review.name}</span> on{" "}
            <span className={styles.date}>{review.date}</span>
            {" - "}
            <a href={review.amazon.link}>{review.amazon.at}</a>
          </p>
          <p className={styles.review}>
            <Nl2br str={review.text} />
          </p>
        </div>
      </div>
    ))}

    <AmazonButton />
    <Divider />
  </div>
)
