import React from "react"
import styles from "./humburger.module.css"

export default ({ active, clickHandler }) => {
  const firstClass = `${styles.first} ${active && styles.first_active}`
  const secondClass = `${styles.second} ${active && styles.second_active}`
  const thirdClass = `${styles.third} ${active && styles.third_active}`

  return (
    <span className={styles.humburger} onClick={clickHandler}>
      <span className={firstClass} />
      <span className={secondClass} />
      <span className={thirdClass} />
    </span>
  )
}
