import React from "react"
import FULL_STAR from "../../assets/star_full.svg"
import HALF_STAR from "../../assets/star_half.svg"
import EMPTY_STAR from "../../assets/star_empty.svg"
import styles from "./stars.module.css"

const FullStar = () => <img className={styles.icon} src={FULL_STAR} alt="1" />
const HalfStar = () => <img className={styles.icon} src={HALF_STAR} alt="0.5" />
const EmptyStar = () => <img className={styles.icon} src={EMPTY_STAR} alt="0" />

export default ({ score }) => {
  const fullStars = new Array(Math.floor(score)).fill(1)
  const emptyStars = new Array(5 - Math.ceil(score)).fill(0)
  const stars =
    score % 1 !== 0
      ? fullStars.concat([0.5]).concat(emptyStars)
      : fullStars.concat(emptyStars)

  return (
    <div className={styles.stars}>
      {stars.map((v, i) => (
        <span key={`star-${i}`} className={styles.star}>
          {v === 1 ? <FullStar /> : v === 0.5 ? <HalfStar /> : <EmptyStar />}
        </span>
      ))}
    </div>
  )
}
