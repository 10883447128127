import React from "react"
import Divider from "../../components/divider/divider"
import AmazonButton from "../../components/amazonButton/amazonButton"
import main_title from "../../assets/main_title_tmp.png"
import book_img from "../../assets/book1.png"
import styles from "./top.module.css"

export default () => (
  <div id="top" className="container top">
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        <img
          className={styles.title_img}
          src={main_title}
          alt="the weight of love"
        />
      </h1>
      <div className={styles.book_img_wrapper}>
        <img className={styles.book_img} src={book_img} alt="book cover" />
      </div>
    </div>
    <div>
      <AmazonButton />
    </div>
    <Divider />
  </div>
)
