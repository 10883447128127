import React from "react"
import SectionTitle from "../../components/sectionTitle/sectionTitle"
import AmazonButton from "../../components/amazonButton/amazonButton"
import Divider from "../../components/divider/divider"
import COVER_IMG from "../../assets/cover.png"
import styles from "./store.module.css"

export default () => (
  <div id="store" className="container">
    <SectionTitle h={40}>Order</SectionTitle>
    <div className={styles.image_wrapper}>
      <img className={styles.book_cover} src={COVER_IMG} alt="COVER OF BOOK" />
      <span className={styles.deco} />
    </div>
    <p>
      Available in Kindle and paperback on{" "}
      <a href="https://www.amazon.com/dp/B07SC3F83C/ref=cm_sw_em_r_mt_dp_U_lfo9CbFHP70AM">
        Amazon
      </a>
    </p>
    <AmazonButton />
    <Divider />
  </div>
)
