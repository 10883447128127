import React from "react"
import styles from "./sectionTitle.module.css"

export default ({ children, w = 60, h = 80 }) => (
  <div className={styles.wrapper}>
    <h2 className={styles.title}>{children}</h2>
    <span
      className={styles.deco}
      style={{
        top: `calc(50% - ${h / 2}px)`,
        left: `calc(50% - ${w / 2}px)`,
        width: `${w}px`,
        height: `${h}px`,
      }}
    />
  </div>
)
