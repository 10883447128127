import React from "react"
import SectionTitle from "../../components/sectionTitle/sectionTitle"
import AmazonButton from "../../components/amazonButton/amazonButton"
import Divider from "../../components/divider/divider"
import styles from "./authour.module.css"

export default () => (
  <div id="authour" className="container about-the-authour">
    <SectionTitle>
      ABOUT
      <br />
      THE
      <br />
      AUTHOR
    </SectionTitle>
    <p>
      <span className={styles.b}>Sunna Bohlen</span> is a fearless{" "}
      <a
        className={styles.bold_link}
        href="https://www.sunnabohlen.com/painting/"
      >
        painter
      </a>
      , a closet{" "}
      <a
        className={styles.bold_link}
        href="https://www.sunnabohlen.com/poetry/"
      >
        poet
      </a>
      , and a soul cook. Life has taught her many lessons, the first of which is
      the power of self-discovery as the best medicine for healing. Her creative
      work is a passionate study of the human condition. Through her art, she
      seeks to share her experiences and insights with the world. She believes
      that giving unconditional love is the secret to happiness. The Weight of
      Love is her first novel. You can find more of her work at{" "}
      <a className={styles.bold_link} href="https://www.sunnabohlen.com">
        www.sunnabohlen.com
      </a>
      .
    </p>
    <AmazonButton />
    <Divider />
  </div>
)
