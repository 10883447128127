import React, { useState } from "react"
import Humburger from "../humburger/humburger"
import Footer from "../../sections/footer/footer"
import AMAZON_LOGO from "../../assets/amazon.svg"
import menu_items from "./menu_items.json"
import styles from "./menu.module.css"

const DesktopMenu = () => (
  <div className={styles.desktop_wrapper}>
    <div className={styles.left}>
      <ul className={styles.desktop_menu_items}>
        {menu_items.map((m, i) => (
          <li key={`menu-${i}`} className={styles.desktop_menu_item}>
            <a
              className={styles.desktop_menu_link}
              href={m.href}
              dangerouslySetInnerHTML={m.children}
            />
          </li>
        ))}
      </ul>
    </div>
    <div className={styles.right}>
      <a
        className={styles.amazon_wrapper}
        href="https://www.amazon.com/dp/B07SC3F83C/ref=cm_sw_em_r_mt_dp_U_lfo9CbFHP70AM"
      >
        GO TO THE STORE
        <img className={styles.amazon_logo} src={AMAZON_LOGO} alt="amazon" />
      </a>
    </div>
  </div>
)

const MobileMenu = () => {
  const [active, setActive] = useState(false)
  const toggleMenu = () => setActive(!active)

  return (
    <div
      className={`menu ${styles.mobile_wrapper}`}
      style={{
        background: active ? "#EB332A" : "none",
      }}
    >
      <div className={styles.humburger_wrapper}>
        <Humburger active={active} clickHandler={toggleMenu} />
      </div>
      {active && (
        <>
          <ul className={`container ${styles.mobile_container}`}>
            {menu_items.map((m, i) => (
              <li key={`menu-${i}`} className={styles.mobile_menu_item}>
                <a
                  className={styles.mobile_menu_link}
                  href={m.href}
                  onClick={toggleMenu}
                  dangerouslySetInnerHTML={m.children}
                />
              </li>
            ))}
          </ul>
          <Footer isInMenu />
        </>
      )}
    </div>
  )
}

export default () => (
  <>
    <MobileMenu />
    <DesktopMenu />
  </>
)
