import React from "react"
import { Link } from "gatsby"
import SectionTitle from "../../components/sectionTitle/sectionTitle"
import AmazonButton from "../../components/amazonButton/amazonButton"
import Divider from "../../components/divider/divider"
import SAMPLE_IMG from "../../assets/sample_book_tmp.png"
import styles from "./sample.module.css"

export default () => (
  <div id="sample" className="container">
    <SectionTitle>
      ABOUT
      <br />
      THE
      <br />
      BOOK
    </SectionTitle>
    <p>
      Born in South Korea on the day the Korean War begins, Hana struggles to
      overcome the restrictions of her family, her physical disability, and
      traditional society. Against the odds, she‘s determined to pursue her
      creativity, to live and love freely. When she falls in love for the first
      time, she emigrates to California, and her world breaks open in unexpected
      ways. Through the ups and downs of different kinds of love, she learns the
      importance of generosity, self-discovery, and resilience. This is the
      story of one woman’s daily struggle to maintain her balance, to pick
      herself up when she fails, and to find beauty in imperfection – both in
      herself and in those she loves. On her seventieth birthday, as Hana looks
      back over her life, she wonders what new adventure is waiting around the
      next corner.
    </p>
    <p>
      <Link className={styles.link} to="/sample/">
        Read Sample chapter &gt;
      </Link>
    </p>
    <Link className={styles.img_wrapper} to="/sample/">
      <img className={styles.sample_img} src={SAMPLE_IMG} alt="sample book" />
    </Link>
    <AmazonButton />
    <Divider />
  </div>
)
